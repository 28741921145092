<template>
<v-ons-page class="charactor">
	<common-header
		type="allow"
		@click="$router.back()"
	>
		<h1 class="common-header-title__heading">キャラクター紹介</h1>
	</common-header>
	<div class="charactor-container" id="js_charactor_container">
		<section class="charactor-intro">
			<h2 class="charactor-intro-heading"><span class="charactor-intro-heading__bg sprite sprite-charactor_title01"></span></h2>
			<div class="charactor-intro-contents">
				<div class="charactor-intro-contents__img"><span class="charactor-intro-contents__bg sprite sprite-charactor_img01"></span></div>
				<span class="charactor-intro-contents__name">ふくろう博士（ヒロシ）</span>
				<p>気象学博士。気象予報士。<br>男手ひとつで子猫のマロを育てている。<br>苦労を苦労と感じない努力家。<br>本名は不苦労 博士（ふくろう ひろし）</p>
			</div>
			<div class="charactor-intro-contents">
				<div class="charactor-intro-contents__img"><span class="charactor-intro-contents__bg sprite sprite-charactor_img02"></span></div>
				<span class="charactor-intro-contents__name">マロ</span>
				<p>ふくろう博士(ヒロシ)の養子。雑種。<br>ある寒い雨の日にひとりで泣いているところを、博士(ヒロシ)に保護されたという。<br>ぼんやり空を眺めるのが好き。<br>いつかは自分も空を飛べると思っている。</p>
			</div>
			<div class="charactor-intro-contents">
				<div class="charactor-intro-contents__img charactor-intro-contents__img--pt50"><span class="charactor-intro-contents__bg sprite sprite-charactor_img03"></span></div>
				<span class="charactor-intro-contents__name">てるてるネコ</span>
				<p>謎のてるてる坊主。<br>何故かいつもマロを見守っている。</p>
			</div>
		</section>

		<section class="charactor-story">
			<h2 class="charactor-story-heading">～ストーリー～</h2>
			<div class="charactor-story-contents">
				<p>気象予報士のふくろう博士(ヒロシ)は狼狽していた。<br>
				行楽日和と予報したはずの森のその日の天気が昼頃から急変し大荒れになってしまったのだ。<br>
				森へピクニックに出かけた猫の親子が行方不明だとの知らせが飛んできた。<br>
				博士(ヒロシ)は大荒れの天気の中、森の奥で猫の親子を見つける。母猫は子猫を博士(ヒロシ)にあずけると息絶えた。<br>
				母猫は病弱でいつ死んでもおかしくなかった。最後の思い出にとピクニックに出かけたのだ。<br>
				気象予報士のふくろう博士(ヒロシ)は、自分の予報のせいで母親を死なせたのではないかと悩む。<br>
				博士(ヒロシ)はマロを立派に育て上げる事を決意した。<br>
				無邪気なマロはいつも空ばかり見ている。<br>
				「お空はどうしていろいろ変わるのかニャー。」<br>
				「博士(ヒロシ)みたいに空にもっと詳しくなりたいニャ。」<br>
				雨のあの日の記憶が有るのか無いのかマロはこうつぶやいた。<br>
				「明日は晴れるといいニャ。」</p>
			</div>
			<div class="charactor-story-img"><span class="charactor-story-img__bg sprite sprite-charactor_img04"></span></div>
		</section>
	</div>
</v-ons-page>
</template>

<script>
// Compornents
import CommonHeader from '../components/Molecules/CommonHeader'

// Vuex
import { mapGetters } from 'vuex'

export default {
	name: 'Charactor',
	components: {
		CommonHeader
	},
	data () {
		return {
		}
	},
	computed: {
		...mapGetters('common', ['viewMode'])
	},
	mounted () {
		// スゴ得の場合、コンテンツの高さをemitする。
		const domContainer = document.getElementById('js_charactor_container')
		if (this.viewMode === 'sugotoku' && domContainer) {
			this.$emit('containerHeight', domContainer.clientHeight)
		}
	},
	beforeDestroy () {
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

.charactor {
	&-container {
		overflow: hidden;
		background: $background-primary;
	}
	&-intro {
		&-heading {
			position: relative;
			height: 72px; // NOTE: 表示画像の高さ
			transform: scale(0.6); // NOTE: 表示画像の高さ / 元画像の高さ
			&__bg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
			}
		}
		&-contents {
			padding: 0 $spacing-20;
			&__img {
				position: relative;
				height: 190px; // NOTE: 表示画像の高さ
				transform: scale(0.62); // NOTE: 表示画像の高さ / 元画像の高さ
				&--pt50 {
					padding-top:  3.125rem; // NOTE: 50px
				}
			}
			&__bg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
			}
			&__name {
				display: block;
				font-size: $font-size-20;
				font-weight: bold;
				text-align: center;
			}
			p {
				font-size: $font-size-16;
				margin: $spacing-6 0 0;
			}
		}
	}
	&-story {
		padding: 0 $spacing-10;
		&-heading {
			margin-bottom: $spacing-10;
			padding-top: $spacing-20;
			text-align: center;
		}
		&-contents {
			border: 2px solid $charactor-story-border;
			border-radius: 15px;
			padding: $spacing-10;
			p {
				margin: 0;
			}
		}
		&-img {
			position: relative;
			height: 190px; // NOTE: 表示画像の高さ
			padding: $spacing-20 0;
			transform: scale(0.62); // NOTE: 表示画像の高さ / 元画像の高さ
			&__bg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
			}
		}
	}
	.sprite {
		background-image: url(~@/assets/img/sprite_charactor.png);
		background-repeat: no-repeat;
		display: block;
		&.sprite-charactor_img01 {
			width: 464px;
			height: 308px;
			background-position: 0 0;
		}
		&.sprite-charactor_img02 {
			width: 464px;
			height: 308px;
			background-position: 0 -308px;
		}
		&.sprite-charactor_img03 {
			width: 464px;
			height: 308px;
			background-position: 0 -616px;
		}
		&.sprite-charactor_img04 {
			width: 464px;
			height: 308px;
			background-position: 0 -924px;
		}
		&.sprite-charactor_title01 {
			width: 640px;
			height: 121px;
			background-position: 0 -1232px;
		}
	}
}
</style>
